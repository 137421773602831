import {
    Accordion,
    AccordionItem,
    AccordionItemContent,
    AccordionItemHeader,
    Box,
    InfoTooltip,
} from "@edgetier/client-components";
import { Button, SpinnerUntil, Tooltip } from "@edgetier/components";
import { doNothing } from "@edgetier/utilities";
import { faCheck, faChevronRight, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { CSSProperties, memo, useCallback, useMemo } from "react";

import AddItemButton from "./add-item-button";
import SelectBulkButtons from "./select-bulk-buttons";
import SelectListTitle from "./select-list-title";
import { IProps } from "./select-menu.types";
import SelectSelectedList from "./select-selected-list";
import {
    APPLY_BUTTON_TEXT,
    CANCEL_BUTTON_TEXT,
    DEFAULT_VIRTUALIZED_ITEM_HEIGHT,
    MINIMUM_TAGS_TO_OPEN_ACCORDIONS,
    RENDERED_VIRTUALIZE_ELEMENTS,
} from "~/select.constants";
import { calculateGroupedFlatItemIndex } from "./select-menu.utilitites";
import "./select-menu.scss";
import SelectMenuMessage from "./select-menu-message/select-menu-message";
import { FixedSizeList } from "react-window";

/**
 * Menu to allow users to choose one or more items from a list.
 * @param props.allItems                    An array of all the items, regardless of whether they are selected/unselected.
 * @param props.addItemMenu                 A menu for adding an item to the select.
 * @param props.addItemButtonProps          Props for the add item button.
 * @param props.children                    Optional method to render items.
 * @param props.close                       Method to close the menu.
 * @param props.description                 Description of items to be selected.
 * @param props.disableMenuItems            Whether the menu items should be disabled or not.
 * @param props.getGroup                    Getter for an item's group name.
 * @param props.getLabel                    Getter for an item's label.
 * @param props.getValue                    Getter for an item's value.
 * @param props.getIcon                     Getter for an item's icon.
 * @param props.isLoading                   Optional loading state of the items.
 * @param props.isSingleSelect              Whether one or more items can be selected.
 * @param props.message                     An optional message to show in the select menu.
 * @param props.noItemsFoundLabel           Label to show when no items are found.
 * @param props.onSelectItems               Handler when items are selected.
 * @param props.onMassSelect                Handler when all items are selected or deselected.
 * @param props.onInputChange               A function that gets called when the select's input value changes.
 * @param props.highlightedIndex            Index of the highlighted item.
 * @param props.getItemProps                Getter for the props of an item.
 * @param props.getSelectedItemProps        Getter for the props of a selected item.
 * @param props.removeSelectedItem          Method to remove a selected item.
 * @param props.selectedItems               Items selected.
 * @param props.setSelectedItems            Method to set the selected items.
 * @param props.notSelectedItems            Items not selected.
 * @param props.selectedValues              Values selected before opening the menu.
 * @param props.selectedItemsLimit          Maximum number of items that can be selected.
 * @param props.itemsSelectedBeforeOpen     The items that were selected before the select menu was opened.
 * @param props.virtualized                 If the lists should be virtualized.
 * @param props.itemheight                  The list item height, if virtualized.
 * @param props.titleSection                An optional configuration for the title section. Title section won't be shown if not provided.
 * @param props.titleSection.title          The title being showed in the title section.
 * @param props.titleSection.tooltipContent The tooltip content for the title section. Tooltip won't be shown if not provided.
 * @param props.hideBulkActionSelectAll     Whether to hide the "select all" bulk action button.
 * @param props.hideBulkActionDeselectAll   Whether to hide the "deselect all" bulk action button.
 * @returns Menu of items to choose.
 */
const SelectMenu = <IItem extends {}, IValue extends {} = string>({
    allItems,
    addItemMenu,
    addItemButtonProps,
    children,
    close,
    description,
    disableMenuItems = false,
    isItemDisabled,
    getGroup,
    getLabel,
    getValue,
    getIcon,
    isCompact = false,
    isLoading = false,
    isSingleSelect = false,
    message,
    noItemsFoundLabel,
    onSelectItems,
    onMassSelect = doNothing,
    highlightedIndex,
    getItemProps,
    getSelectedItemProps,
    removeSelectedItem,
    selectedItems,
    setSelectedItems,
    notSelectedItems,
    selectedValues,
    menuListProps,
    isMultipleSelectCheckbox,
    enableAutoFocus = true,
    messageTooltip,
    selectedItemsLimit,
    itemsSelectedBeforeOpen = selectedItems, // Default to selectedItems as these will match most of the time.
    hideBulkActionSelectAll,
    hideBulkActionDeselectAll,
    virtualized,
    itemHeight = DEFAULT_VIRTUALIZED_ITEM_HEIGHT,
    titleSection,
    ...other
}: IProps<IItem, IValue>) => {
    const isMobileMultiSelect = isMultipleSelectCheckbox && !isSingleSelect;

    const { flatItems, flatItemIndexMap, groupedItemsWithIndex } = useMemo(
        () => calculateGroupedFlatItemIndex(notSelectedItems, getGroup),
        [notSelectedItems, getGroup]
    );

    const itemLimit = useMemo(
        () => (typeof selectedItemsLimit === "number" ? selectedItemsLimit : selectedItemsLimit?.limit),
        [selectedItemsLimit]
    );
    const limitMessage = useMemo(
        () => (typeof selectedItemsLimit === "number" ? `${description} limit reached` : selectedItemsLimit?.message),
        [selectedItemsLimit, description]
    );
    const limitReached = useMemo(() => {
        if (typeof itemLimit === "undefined") {
            return false;
        }
        return selectedItems.length >= itemLimit;
    }, [selectedItems, itemLimit]);

    const { flatItemIndexMap: mobileFlatItemIndexMap } = useMemo(() => {
        // Calculate grouped flat items for filtered items
        return calculateGroupedFlatItemIndex(notSelectedItems, getGroup);
    }, [getGroup, notSelectedItems]);

    /**
     * Select some items and close the menu.
     * @param items Newly selected items.
     */
    const selectAndClose = (items: IItem[]) => {
        onSelectItems(items.map(getValue), items);
        close();
    };

    /**
     * Close the menu and return the selected options.
     */
    const onApply = (currentlySelectedItems: IItem[]) => {
        selectAndClose(currentlySelectedItems);
    };

    /**
     * Select all items.
     * If there is an item limit, select the first n items in the list
     */
    const onSelectAll = useCallback(() => {
        if (typeof itemLimit !== "undefined") {
            const itemsToSelect = notSelectedItems.slice(0, itemLimit - selectedItems.length);
            setSelectedItems(selectedItems.concat(itemsToSelect));
        } else {
            setSelectedItems(selectedItems.concat(notSelectedItems));
        }
        onMassSelect();
    }, [notSelectedItems, onMassSelect, selectedItems, itemLimit, setSelectedItems]);

    /**
     * Clear all items.
     */
    const onSelectNone = useCallback(() => {
        setSelectedItems([]);
        onMassSelect();
    }, [onMassSelect, setSelectedItems]);

    const hasMultipleGroups = useMemo(() => Object.keys(groupedItemsWithIndex).length > 1, [groupedItemsWithIndex]);

    /**
     * Create a list item to be shown in the menu.
     * @param item  Item to be shown.
     * @param index Index of the item.
     */
    const createListItem = (item: IItem, index: number, style?: CSSProperties) => {
        const value = getValue(item);
        const key = typeof value === "object" ? Object.values(value).join() : value.toString();
        const isSelected = selectedItems.includes(item);

        return (
            <li key={key} data-testid={[other?.["data-testid"], key].filter(Boolean).join("_")} style={style}>
                {!hasMultipleGroups &&
                    typeof getGroup === "function" &&
                    (index === 0 || getGroup(item) !== getGroup(notSelectedItems[index - 1])) && (
                        <div aria-label={getGroup(item)} className="select-menu__group-title">
                            {getGroup(item)}
                        </div>
                    )}
                <div
                    aria-label={getLabel(item)}
                    className={classNames("select-menu__option", {
                        "select-menu__option--is-highlighted": index === highlightedIndex,
                        "select-menu__option--is-disabled": isItemDisabled?.(item) || disableMenuItems || limitReached,
                    })}
                    {...getItemProps({
                        item,
                        index: index,
                    })}
                >
                    <div className="select-menu__option__label">
                        {typeof getIcon === "function" ? (
                            <span className="select-menu__option__label__icon">
                                <FontAwesomeIcon icon={getIcon(item)} />
                            </span>
                        ) : null}
                        {isMobileMultiSelect ? (
                            <div className="select-menu__option-checkbox-container">
                                <input type="checkbox" checked={isSelected} onChange={() => {}} />
                                {typeof children === "function" ? children(item) : getLabel(item)}
                            </div>
                        ) : (
                            <>{typeof children === "function" ? children(item) : getLabel(item)}</>
                        )}
                    </div>
                    {!isSingleSelect && !isMultipleSelectCheckbox && <FontAwesomeIcon icon={faChevronRight} />}
                </div>
            </li>
        );
    };

    const hasNoItems = useMemo(
        () => notSelectedItems.length === 0 && selectedItems.length === 0,
        [notSelectedItems.length, selectedItems.length]
    );

    const multiSelectHasNoItems = useMemo(
        () => !isSingleSelect && notSelectedItems.length === 0,
        [isSingleSelect, notSelectedItems.length]
    );

    const withoutMultipleGroupsItems = useMemo(() => {
        return Array.from(isMobileMultiSelect ? mobileFlatItemIndexMap.entries() : flatItemIndexMap.entries());
    }, [isMobileMultiSelect, mobileFlatItemIndexMap, flatItemIndexMap]);

    return (
        <Box
            className={classNames("select-menu", {
                "select-menu--is-multiple-select": !isSingleSelect && !isMultipleSelectCheckbox,
                "select-menu--is-single-select": isSingleSelect,
            })}
        >
            {titleSection && (
                <div className="select-menu__title">
                    <h5>{titleSection.title}</h5>{" "}
                    {titleSection.tooltipContent && (
                        <InfoTooltip placement="left-center" content={titleSection.tooltipContent} />
                    )}
                </div>
            )}
            <div className="select-menu__lists">
                <div className="select-menu__list select-menu__list--not-selected">
                    {!isSingleSelect && !isMultipleSelectCheckbox && (
                        <SelectListTitle count={notSelectedItems.length} title="Not Selected" />
                    )}

                    <div className="select-menu__options">
                        {typeof message !== "undefined" &&
                            (typeof messageTooltip !== "undefined" ? (
                                <Tooltip content={messageTooltip} useArrow>
                                    <SelectMenuMessage message={message} />
                                </Tooltip>
                            ) : (
                                <SelectMenuMessage message={message} />
                            ))}

                        {/** Use itemsSelectedBeforeOpen here because that will be more up-to-date than selectedItems */}
                        {!isCompact && isSingleSelect && itemsSelectedBeforeOpen.length > 0 && (
                            <div className="select-menu__selected-item">
                                {typeof getIcon === "function" ? (
                                    <span className="select-menu__option__label__icon">
                                        <FontAwesomeIcon icon={getIcon(itemsSelectedBeforeOpen[0])} />
                                    </span>
                                ) : null}

                                <>
                                    {typeof children === "function"
                                        ? children(itemsSelectedBeforeOpen[0])
                                        : getLabel(itemsSelectedBeforeOpen[0])}
                                </>
                            </div>
                        )}

                        {!isLoading && (hasNoItems || multiSelectHasNoItems) && (
                            <div className="select-menu__options--empty">
                                {typeof noItemsFoundLabel === "undefined"
                                    ? `No ${description}s found`
                                    : noItemsFoundLabel}
                            </div>
                        )}

                        <SpinnerUntil data={[]} isReady={!isLoading}>
                            {!hasMultipleGroups ? (
                                virtualized ? (
                                    <FixedSizeList
                                        height={
                                            itemHeight *
                                            Math.min(RENDERED_VIRTUALIZE_ELEMENTS, withoutMultipleGroupsItems.length)
                                        }
                                        width={"100%"}
                                        itemSize={itemHeight}
                                        itemCount={withoutMultipleGroupsItems.length}
                                        innerElementType={(props) => (
                                            <ul className="select-menu__list" {...props} {...menuListProps} />
                                        )}
                                    >
                                        {({ index, style }) =>
                                            createListItem(withoutMultipleGroupsItems[index][0], index, {
                                                ...style,
                                                height: itemHeight,
                                            })
                                        }
                                    </FixedSizeList>
                                ) : (
                                    <ul className="select-menu__list" {...menuListProps}>
                                        {withoutMultipleGroupsItems.map(([item, index]) => createListItem(item, index))}
                                    </ul>
                                )
                            ) : (
                                <ul className="select-menu__list" {...menuListProps}>
                                    {Object.entries(groupedItemsWithIndex).map(([group, items]) => (
                                        <Accordion key={group}>
                                            <AccordionItem
                                                canOpen
                                                isOpen={flatItems.length < MINIMUM_TAGS_TO_OPEN_ACCORDIONS}
                                                key={group}
                                            >
                                                <AccordionItemHeader>
                                                    <div aria-label={group}>{group}</div>
                                                </AccordionItemHeader>
                                                <AccordionItemContent>
                                                    {items.map(({ item }, index) => {
                                                        const flatItemIndex = flatItemIndexMap.get(item);
                                                        return createListItem(item, flatItemIndex ?? index);
                                                    })}
                                                </AccordionItemContent>
                                            </AccordionItem>
                                        </Accordion>
                                    ))}
                                </ul>
                            )}
                        </SpinnerUntil>
                    </div>

                    {typeof addItemMenu !== "undefined" && (
                        <div className="select-menu__add-item">
                            <AddItemButton addItemMenu={addItemMenu} {...addItemButtonProps} />
                        </div>
                    )}
                </div>
                {!isSingleSelect && !isMultipleSelectCheckbox && (
                    <SelectBulkButtons
                        isDisabled={disableMenuItems}
                        notSelectedItemsCount={notSelectedItems.length}
                        onSelectAll={onSelectAll}
                        onSelectNone={onSelectNone}
                        selectedItemsCount={selectedItems.length}
                        limitReached={limitReached}
                        hideBulkActionSelectAll={hideBulkActionSelectAll}
                        hideBulkActionDeselectAll={hideBulkActionDeselectAll}
                    />
                )}

                {!isSingleSelect && !isMultipleSelectCheckbox && (
                    <SelectSelectedList
                        getGroup={getGroup}
                        getLabel={getLabel}
                        getSelectedItemProps={getSelectedItemProps}
                        getValue={getValue}
                        isLoading={isLoading}
                        removeSelectedItem={removeSelectedItem}
                        selectedItems={selectedItems}
                        limitReached={limitReached}
                        limitMessage={limitMessage}
                        virtualized={virtualized}
                        itemHeight={itemHeight}
                    >
                        {children}
                    </SelectSelectedList>
                )}
            </div>

            {!isSingleSelect && (
                <div className="select-menu__controls">
                    {!isMultipleSelectCheckbox && (
                        <Button icon={faTimes} onClick={() => close()} styleName="neutral">
                            {CANCEL_BUTTON_TEXT}
                        </Button>
                    )}
                    <Button
                        disabled={isLoading || (typeof itemLimit !== "undefined" && selectedItems.length > itemLimit)}
                        icon={faCheck}
                        onClick={() => onApply(selectedItems)}
                        styleName={isMultipleSelectCheckbox ? "primary" : "positive"}
                        className={
                            isMultipleSelectCheckbox
                                ? "select-menu__controls-mobile-apply"
                                : "select-menu__controls-apply"
                        }
                    >
                        {APPLY_BUTTON_TEXT}
                    </Button>
                </div>
            )}
        </Box>
    );
};

export default memo(SelectMenu) as typeof SelectMenu;
